import React from 'react';
import parse from 'html-react-parser';
import getSymbolFromCurrency from 'currency-symbol-map';

import { BorderRadius } from '../../utils/variables';
import { valueStyle } from './style';

import iconSelected from '../../images/icon-selected.svg';

import loadComponent from '../Loadable';
import loadLibrary from '../Loadable/library';

const Img = loadLibrary('gatsby-image');

const H3 = loadComponent('h3');
const Placeholder = loadComponent('product-placeholder');
const ButtonHidden = loadComponent('button-hidden');

export default function ProductCategoryItems({
  data,
  index,
  active,
  setActive,
}) {
  const price = data.onSale ? data.salePrice : data.regularPrice;
  const isSelected = active === data.link;

  return (
    <li
      className={`item${isSelected ? ` selected` : ``}`}
      style={{ marginTop: index !== 0 && `15px` }}
    >
      <ButtonHidden
        onClick={() => setActive(data.link)}
        style={{
          border: `1px solid rgba(0,0,0,0.2)`,
          borderRadius: BorderRadius,
          padding: `30px`,
          backgroundColor: isSelected ? `#fff` : `#f9f9f9`,
        }}
      >
        <div className="button">
          <div className="image">
            {data?.featuredImage ? (
              <Img
                image={
                  data?.featuredImage?.node?.localFile?.childImageSharp
                    ?.gatsbyImageData
                }
                alt={data.name}
              />
            ) : (
              <Placeholder alt={data.name} />
            )}
          </div>
          <div className="content">
            <H3 size="1.375rem" margin="15px">
              {data.name}
            </H3>
            {parse(data.shortDescription)}
          </div>
          <div className="itemPrice">
            {isSelected ? (
              <img
                src={iconSelected}
                alt={`${data.name} selected`}
                className="selected"
              />
            ) : (
              <div
                style={{
                  height: `24px`,
                  width: `24px`,
                  borderRadius: `24px`,
                  border: `2px solid rgba(0,0,0,0.2)`,
                }}
              />
            )}
            {isSelected && (
              <span className="value" style={valueStyle}>
                {getSymbolFromCurrency(data.options.currency)}
                {parseFloat(price).toFixed(2)}
              </span>
            )}
            {/* {data.onSale && (
              <span className="sale" style={saleStyle}>
                &pound;{parseFloat(data.regularPrice).toFixed(2)}
              </span>
            )}
            */}
          </div>
        </div>
      </ButtonHidden>
    </li>
  );
}

// const badgeSize = `30px`;

// const badgeShared = {
//   display: `flex`,
//   alignItems: `center`,
//   justifyContent: `center`,
//   height: badgeSize,
//   width: badgeSize,
//   position: `absolute`,
//   zIndex: 20,
// };

// const saleBadge = {
//   backgroundColor: MainColour,
//   color: `#fff`,
//   textTransform: `uppercase`,
//   fontWeight: Bold,
//   fontSize: `0.625rem`,
//   borderRadius: badgeSize,
//   ...badgeShared,
//   top: `30px`,
//   left: `30px`,
//   pointerEvents: `none`,
// };

import { Bold, Light, TitleColour } from '../../utils/variables';

export const saleStyle = {
  fontSize: `0.875rem`,
  color: `red`,
  textDecoration: `line-through`,
  fontWeight: Light,
  marginRight: `0.3125rem`,
};

export const valueStyle = {
  fontSize: `1.5rem`,
  color: TitleColour,
  fontWeight: Bold,
  display: `block`,
  marginTop: `15px`,
};

const topBottomPadding = `1.875rem`;
const topBottomBorder = `1px solid rgba(0,0,0,0.1)`;

export const topBottomShared = {
  display: `flex`,
  alignItems: `center`,
};

export const topStyle = {
  ...topBottomShared,
  justifyContent: `space-between`,
  marginBottom: topBottomPadding,
  paddingBottom: topBottomPadding,
  borderBottom: topBottomBorder,
};

export const bottomStyle = {
  ...topBottomShared,
  justifyContent: `space-between`,
  marginTop: topBottomPadding,
  paddingTop: topBottomPadding,
  borderTop: topBottomBorder,
};
